import React, { useState } from 'react';
import { Drawer } from 'antd';
import { AiOutlineClose, AiOutlineInfoCircle } from 'react-icons/ai';
import styles from './ExpressProposta.module.css';
import { useTranslation } from 'react-i18next';

export default function ExpressInfoDrawer({ isDarkTheme }) {
    const { t } = useTranslation();
    const [isDrawerVisible, setIsDrawerVisible] = useState(false);

    const showDrawer = () => {
        setIsDrawerVisible(true);
    };

    const closeDrawer = () => {
        setIsDrawerVisible(false);
    };

    return (
        <>
            {/* Icon with click handler to open drawer */}
            <AiOutlineInfoCircle className={styles.iconQuestion} onClick={showDrawer} />

            {/* Drawer Component */}
            <Drawer
                placement="right"
                onClose={closeDrawer}
                open={isDrawerVisible}
                closeIcon={null} // Remove the default close icon
                width={600}
                drawerStyle={{
                    backgroundColor: isDarkTheme ? '#27272A' : '#F7F7F7',
                    color: isDarkTheme ? '#fff' : '#333',
                }}
                className={`${isDarkTheme ? styles.drawerContentDark : styles.drawerContentLight} express-class`}
            >
                {/* Custom Header */}
                <div className={styles.drawerHeader}>
                    <span className={isDarkTheme ? styles.drawerTitleDark : styles.drawerTitleLight}>
                        {t('Express Information')}
                    </span>
                    <AiOutlineClose
                        className={styles.closeIcon}
                        style={{ color: isDarkTheme ? '#fff' : '#333' }}
                        onClick={closeDrawer}
                    />
                </div>

                {/* Drawer Content */}
                <div className={styles.drawerBody}>
                    <p className={styles.textBase}>
                        O Importrust Express é o serviço ideal para quem procura uma entrega mais rápida do seu novo carro, sem comprometer a segurança e a qualidade.
                    </p>

                    <p className={styles.textBase}>
                        Com o Importrust Express, pode ter o seu carro em menos de 2 semanas, graças à nossa parceria com um operador logístico de confiança, que assegura o transporte do veículo desde o país de origem.
                    </p>

                    <p className={styles.textBase}>
                        O processo é feito com o carro em circulação, garantindo uma entrega eficiente e segura (incluindo seguro de transporte).
                    </p>

                    <p className={styles.textBase}>
                        O Importrust Express reduz a duração do processo de 30-40 dias para apenas 15, começando a contar a partir do momento em que o stand recebe o pagamento.
                    </p>
                </div>
            </Drawer>
        </>
    );
}
