import React from 'react';
import { FaRegCircleCheck } from "react-icons/fa6";
import { useTranslation } from 'react-i18next';
import styles from './StatusCompletedDocumentos.module.css';

export default function StatusCompletedDocumentos() {
    const { t } = useTranslation();

    return (
        <div className={styles.container}>
            <FaRegCircleCheck className={styles.icon} />
            <p className={styles.title}>{t('documentos.validated')}</p>
            <p className={styles.message}> {t('documentos.documentsValidated')}</p>
            <p className={styles.message}>{t('documentos.willBeContacted')}</p>
            <p className={styles.thankYou}>{t('documentos.thankYou')}</p>
        </div>

    );
}
