import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Loading from '../../../components/Elements/Loading/Loading';
import styles from '../PerfilProposta.module.css';
import TabelaDadosCarroProposta from '../../../components/Propostas/TabelaDadosDoCarro';
import PrecoFinalProposta from '../../../components/Propostas/PrecoFinalProposta';
import EstimativaDaViatura from '../../../components/Propostas/EstimativaDaViatura';
import InfosBusinessDeveloper from '../../../components/InfosBusinessDeveloper/InfosBusinessDeveloper';
import HeroProposta from '../../../components/HeroProposta/HeroProposta';
import AceitarProposta from '../../../components/Elements/AceitarProposta/AceitarProposta';
import Partilhar from '../../../components/Elements/Partilhar/Partilhar';
import FormCamposCalculoPropostaES from '../../../components/Propostas/FormCamposCalculoProposta_ES/FormCamposCalculoProposta_ISV_IUC_ES';

export default function PerfilPropuestaES({ propostaData, parametros, isDarkTheme }) {

    const [loading, setLoading] = useState(false);
    const [dataCarros, setDataCarros] = useState([]);

    const [bdInfo, setBDInfo] = useState([]);

    const [propostaInfo, setPropostaInfo] = useState({
        linkFora: '',
        linkDaProposta: '',
        estadoDaProposta: '',
        idProposta: '',
        standData: [],
        tipoNegocio: '',
        pais: '',
        outros: [],
        dataExpira: '',
        nomeCliente: '',
        linkDocumentacao: '',
    });

    const [isvIucData, setIsvIucData] = useState({
        cilindrada: 0,
        novoUsado: '',
        tipoCombustivel: '',
        emissoesCO2: '',
        dataPrimeiroRegisto: '',
        tipoDeVeiculoParaOutros: 'LigeiroPassageiros',
        tipoDeVeiculoParaInsencoes: '',
        idMarca: '',
        nomeMarca: '',
        idModelo: '',
        nomeModelo: '',
        provincia: '',
        nomeProvincia: '',
        municipio: '',
        nomeMunicipio: '',
        valorViaturaNova: '',
        valorTotalISV: 0,
        valorTotalIUC: 0
    });

    //Valores Totais
    const [valoresTotais, setValoresTotais] = useState({
        precoViaturaComIva: 0,
        precoViaturaSemIva: 0,
        taxaDeServico: 0,
        taxaDeFinanciamento: 0,
        taxaDeFinanciamentoAtivo: 0,
        comissao: 0,
        valorTotalISV: 0,
        valorTotalIUC: 0,
        valorPrecoChaveNaMaoParticular: 0,
        valorPrecoChaveNaMaoEmpresa: 0,
        compsIUC: {
            iucCavalosFiscais: 0,
            iucCidade: '',
            iucMunicipio: '',
            iucPercentagemDescontoEletrico: '',
        },
        compsISV: {
            isvBaseImponible: 0,
            isvBaseImponibleFinal: 0,
            isvComunidad: '',
            isvCuotaTributaria: 0,
            isvMinoracion: 0,
            isvPorcentajeAplicar: 0,
            isvReduccionBaseImponible: 0,
            isvTotalPagar: 0,
            isvValoracionInicial: 0
        }
    });


    // Parsea o nome da proposta da URL usando URLSearchParams
    useEffect(() => {
        const fetchProposta = async () => {
            if (!propostaData) return;

            const allDataCarro = propostaData.data.carro;
            const allValores = propostaData.data.simulacao;
            const allValoresExtra = propostaData.data.simulacao_extras;
            const allProposta = propostaData.data.proposta;
            const allStand = propostaData.data.stand;

            setDataCarros(allDataCarro); // Atualiza os dados dos carros com a resposta

            setPropostaInfo((prevData) => ({
                ...prevData,
                linkFora: allProposta.link_proposta,
                linkDaProposta: allProposta.link_proposta_view,
                estadoDaProposta: allProposta.estado_proposta_hubspot,
                tipoNegocio: allProposta?.documentacao?.info?.tipo_contrato || "",
                idProposta: allProposta.id_proposta,
                pais: allProposta.pais,
                standData: allStand,
                outros: allProposta,
                dataExpira: allProposta.data_expira,
                nomeCliente: allProposta.hubspot?.contacto?.firstname,
                linkDocumentacao: allProposta.link_documentacao,
                propostaExpirou: allProposta.expirou,
                propostaDisponivelOnline: allProposta.indisponivel_online
            }));

            setIsvIucData((prevData) => ({
                ...prevData,
                cilindrada: allValores.cilindrada,
                novoUsado: allValores.novo,
                tipoCombustivel: allValores.tipo_de_combustivel,
                emissoesCO2: allValores.emissoes_numerico,
                dataPrimeiroRegisto: allValores.data_registo,
                tipoDeVeiculoParaOutros: allValores.tipo_de_veiculo,
                tipoDeVeiculoParaInsencoes: allValores.tipo_de_isencoes,
                idMarca: allValores.id_marca,
                nomeMarca: allValores.nome_marca,
                idModelo: allValores.id_modelo,
                nomeModelo: allValores.nome_modelo,
                provincia: allValores.id_cidade,
                nomeProvincia: allValores.nome_cidade,
                municipio: allValores.id_municipio,
                nomeMunicipio: allValores.nome_municipio,
                valorViaturaNova: allValores.valor_viatura_nova,
                valorTotalISV: allValores.isv,
                valorTotalIUC: allValores.iuc,
            }));

            setValoresTotais((prevData) => ({
                ...prevData,
                precoViaturaComIva: allDataCarro.preco_final_carro,
                precoViaturaSemIva: allDataCarro.preco_final_carro_sem_iva,
                validaSeValorNaoTemIva: allValores.tem_iva,
                taxaDeServico: allValores.taxadeservico,
                taxaDeFinanciamento: allValores.comissao_credito,
                taxaDeFinanciamentoAtivo: allValores.check_comissao_credito,
                caucaoFinanciamento: allValores.caucao_comissao_credito,
                comissao: allValores.comissaodeservico,
                valorTotalISV: allValores.isv,
                valorTotalIUC: allValores.iuc,
                valorPrecoChaveNaMaoParticular: allValores.valor_chavenamao_particular,
                valorPrecoChaveNaMaoEmpresa: allValores.valor_chavenamao_empresa,
                caucaoIvaPaisOrigem: allValores.caucao_iva_pais_origem,
                compsIUC: {
                    iucCavalosFiscais: allValoresExtra.iuc_cavalos_fiscais,
                    iucCidade: allValoresExtra.iuc_cidade,
                    iucMunicipio: allValoresExtra.iuc_municipio,
                    iucPercentagemDescontoEletrico: allValoresExtra.iuc_percentagem_desconto_eletrico,
                },
                compsISV: {
                    isvBaseImponible: allValoresExtra.isv_base_imponible,
                    isvBaseImponibleFinal: allValoresExtra.isv_base_imponible_final,
                    isvComunidad: allValoresExtra.isv_comunidad,
                    isvCuotaTributaria: allValoresExtra.isv_cuota_tributaria,
                    isvMinoracion: allValoresExtra.isv_minoracion,
                    isvPorcentajeAplicar: allValoresExtra.isv_porcentaje_aplicar,
                    isvReduccionBaseImponible: allValoresExtra.isv_reduccion_base_imponible,
                    isvTotalPagar: allValoresExtra.isv_total_pagar,
                    isvValoracionInicial: allValoresExtra.isv_valoracion_inicial,
                }
            }));

            setBDInfo(propostaData.data.user)

        };

        fetchProposta();
    }, [propostaData]); // Fim do useEffect




    return (
        <div className={styles.container}>
            {loading ? (
                <Loading
                    isDarkTheme={isDarkTheme}
                />
            ) : (
                <div className={styles.gridContainer}>
                    <HeroProposta
                        propostaInfo={propostaInfo}
                        isDarkTheme={isDarkTheme}
                        parametros={parametros}
                        valoresTotais={valoresTotais}
                        setValoresTotais={setValoresTotais}
                        setPropostaInfo={setPropostaInfo}
                    />
                    <div className={styles.flexRow}>
                        <div className={styles.sectionCarDetails}>
                            <TabelaDadosCarroProposta
                                dataCarros={dataCarros}
                                isvIucData={isvIucData}
                                propostaInfo={propostaInfo}
                                parametros={parametros}
                                isDarkTheme={isDarkTheme}
                            />
                        </div>
                        <div className={styles.sectionCalculation}>
                            <FormCamposCalculoPropostaES
                                isvIucData={isvIucData}
                                isDarkTheme={isDarkTheme}
                            />
                            <PrecoFinalProposta
                                valoresTotais={valoresTotais}
                                setValoresTotais={setValoresTotais}
                                parametros={parametros}
                                isDarkTheme={isDarkTheme}
                            />
                            <EstimativaDaViatura
                                propostaInfo={propostaInfo}
                                valoresTotais={valoresTotais}
                                isDarkTheme={isDarkTheme}
                            />
                            <InfosBusinessDeveloper
                                bdInfo={bdInfo}
                                isDarkTheme={isDarkTheme}
                            />
                            <Partilhar
                                propostaInfo={propostaInfo}
                                isDarkTheme={isDarkTheme}
                                parametros={parametros}
                                bdInfo={bdInfo}
                            />
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}
