import React, { useState, useEffect } from 'react';
import { Tabs, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import styles from './PrecoFinalProposta.module.css';
import PropsPrecoFinalProposta from './PrecoFinalProposta/propsPrecoFinalProposta';
import { AiOutlineInfoCircle } from 'react-icons/ai';

export default function PrecoFinalProposta({ valoresTotais, setValoresTotais, parametros, isDarkTheme }) {

    const { t } = useTranslation();
    const [activeTabKey, setActiveTabKey] = useState("1");


    // const [validaSeValorNaoTemIva, setValidaSeValorNaoTemIva] = useState(true);


    const formatarNumero = (valor) => {
        if (!valor || isNaN(valor)) return '0 €';
        return new Intl.NumberFormat('pt-PT', {
            style: 'currency',
            currency: 'EUR',
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
        }).format(valor);
    };

    // console.log(valoresTotais.precoViaturaComIva == valoresTotais.precoViaturaSemIva)

    // useEffect(() => {
    //     if (valoresTotais.validaSeValorNaoTemIva) {
    //         setValidaSeValorNaoTemIva(false)
    //     }
    // }, [valoresTotais]);

    // console.log(validaSeValorNaoTemIva)

    const validarValordaViatura = () => {
        if (valoresTotais.validaSeValorNaoTemIva == 0) {
            return t('gerar_proposta.validar_valor_viatura'); // Retorna a string traduzida
        }
        return ''; // Retorna string vazia se não for igual
    };

    const onTabChange = (key) => {
        setActiveTabKey(key);
    };

    const items = [
        {
            label: <span className={styles.tabLabel}>{t('main.particular')}</span>,
            key: '1',
            children: (
                <PropsPrecoFinalProposta
                    setValoresTotais={setValoresTotais}
                    valoresTotais={valoresTotais}
                    incluirIVA={true}
                    activeTabKey={activeTabKey}
                    formatarNumero={formatarNumero}
                    parametros={parametros}
                    isDarkTheme={isDarkTheme}
                    // validaSeValorNaoTemIva={validaSeValorNaoTemIva}
                />
            ),
        },
        {
            label: <span className={styles.tabLabelEmpresa}>
                {t('main.empresa')}
                {parametros.language == "pt" && valoresTotais.validaSeValorNaoTemIva == 1 && (
                    <Tooltip title={t('gerar_proposta.i_no_empresa')}>
                        <AiOutlineInfoCircle className='icon-question' />
                    </Tooltip>
                )}
                </span>,
            key: '2',
            children: (
                <PropsPrecoFinalProposta
                    setValoresTotais={setValoresTotais}
                    valoresTotais={valoresTotais}
                    incluirIVA={false}
                    activeTabKey={activeTabKey}
                    formatarNumero={formatarNumero}
                    parametros={parametros}
                    isDarkTheme={isDarkTheme}
                    // validaSeValorNaoTemIva={validaSeValorNaoTemIva}
                />
            ),
        },
    ];

    return (
        <div className={`${isDarkTheme ? styles.cardDark : styles.cardLight}`}>

            <div className={styles.header}>
                <div>
                    <p className={`${isDarkTheme ? styles.darkTitle : styles.whiteTitle}`}>{t('gerar_proposta.resultado_simucao')}</p>
                </div>
            </div>

            <Tabs
                className={styles.tabsContainer}
                activeKey={activeTabKey}
                onChange={onTabChange}
                defaultActiveKey="1"
                tabBarGutter={16}
                centered
                items={items}
            />

            {validarValordaViatura() && (
                <>
                    <hr className={styles.separator} />
                    <div className={styles.validationMessage}>
                        {validarValordaViatura()}
                    </div>
                </>
            )}

        </div>
    );
};
