import { useTranslation } from 'react-i18next';
import StatusCompletedDocumentos from './StatusDocumentos.js/StatusCompletedDocumentos';
import StatusValidationDocumentos from './StatusDocumentos.js/StatusValidationDocumentos';
import StatusRejectedDocumentos from './StatusDocumentos.js/StatusRejectedDocumentos';
import StatusPendingDocumentos from './StatusDocumentos.js/StatusPendingDocumentos';

export default function FormDocumentos({ data, setData, parametros }) {

    const { t } = useTranslation();

    return (
        <>
            {data.status == "completed" ? (
                <StatusCompletedDocumentos

                />
            ) : data.status == "pending" ? (
                <StatusPendingDocumentos
                    data={data}
                    setData={setData}
                    parametros={parametros}
                />
            ) : data.status == "validation" ? (
                <StatusValidationDocumentos

                />
            ) : data.status == "rejected" ? (
                <StatusRejectedDocumentos
                    data={data}
                    setData={setData}
                />
            ) : null}
        </>
    );
}
