import { FaMoon, FaSun } from 'react-icons/fa';
import styles from './Header.module.css';
import logo from '../assets/logo-branco 3.svg';
import logobranco from '../assets/logo-branco 2.svg';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';

export default function Header({ parametros, isDarkTheme, setIsDarkTheme }) {

    const { t } = useTranslation();

    const isMobile = useMediaQuery({
        query: '(max-width: 1023px)'
    });


    // Função para alternar entre os temas claro e escuro
    const toggleTheme = () => {
        setIsDarkTheme(!isDarkTheme);
    };

    return (
        <header>
            <div className={`${styles.card} ${styles.grid} ${styles.gap4} ${styles.contentStart}`}>
                <div className={`${!isDarkTheme ? styles.textBlack : styles.textWhite} ${styles.fullWidth} ${styles.flexColumn} ${styles.gap3}`}>
                    <div className={`${styles.fullWidth} ${styles.flex} ${styles.itemsCenter} ${styles.justifyBetween} ${styles.gap7}`}>
                        <div className={`${styles.flex} ${styles.itemsCenter} ${styles.flexRow} ${styles.gap4}`}>
                            <a href={parametros.language === 'pt' ? 'https://importrust.com' : 'https://importrust.es'} target='_blank' rel="noopener noreferrer">
                                <img
                                    src={!isDarkTheme ? logo : logobranco}
                                    alt="Logo Importrust"
                                    className={styles.logo}
                                />
                            </a>
                            {!isMobile && (
                                <>
                                    <span>|</span>
                                    <span className={styles.textXs}>{t('view.phrase')}!</span>
                                </>
                            )}
                        </div>
                        <button onClick={toggleTheme} className={`${!isDarkTheme ? styles.darkButton : styles.lightButton}`}>
                            {!isDarkTheme ? <FaMoon /> : <FaSun />}
                        </button>
                    </div>
                </div>
            </div>
        </header>
    );
}
