import React from 'react';
import styles from './HeroProposta.module.css';
import { Button, Tooltip } from 'antd';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import AceitarProposta from '../Elements/AceitarProposta/AceitarProposta';
import { useTranslation } from 'react-i18next';
import { RiRadioButtonLine } from "react-icons/ri";


export default function HeroProposta({ isDarkTheme, setPropostaInfo, parametros, propostaInfo, valoresTotais, setValoresTotais }) {

    const { t } = useTranslation();

    return (
        <div className={`${isDarkTheme ? styles.cardDark : styles.cardLight}`}>
            <div className={styles.flexContainer}>
                <div className={styles.flexContainerChild}>

                    <p className={`${styles.name} ${isDarkTheme ? styles.nameDark : styles.nameLight}`}>
                        {t('view.ola')}, {propostaInfo.nomeCliente ? `${propostaInfo.nomeCliente} 👋` : null}
                    </p>

                    <div className={styles.flexContainerH}>


                        {propostaInfo.propostaDisponivelOnline === 1 && (
                            <div className="rounded-full bg-[#b63911] text-sm font-semibold px-3 w-auto inline-flex items-center text-white">
                                <RiRadioButtonLine />
                                <span className="ml-2">{t('tables.proposta_indispon_online')}</span>
                            </div>
                        )}

                        {propostaInfo.propostaExpirou === 1 ? (
                            <div className="rounded-full bg-[#b63911] text-sm font-semibold px-3 w-auto inline-flex items-center text-white">
                                <RiRadioButtonLine />
                                <span className="ml-2">{t('tables.proposta_indispon_intern')}</span>
                            </div>
                        ) : (
                            <p className={styles.expiryText}>
                                {t('view.proposta_expira')} {propostaInfo.dataExpira}
                                <Tooltip title={t('view.proposta_expira_info')}>
                                    <AiOutlineInfoCircle className={styles.iconInfo} />
                                </Tooltip>

                            </p>
                        )}

                    </div>



                </div>

                {parametros.language === 'pt' &&
                    (propostaInfo.propostaExpirou !== 1 || propostaInfo.propostaDisponivelOnline !== 1) && (
                        <AceitarProposta
                            propostaInfo={propostaInfo}
                            isDarkTheme={isDarkTheme}
                            parametros={parametros}
                            valoresTotais={valoresTotais}
                            setValoresTotais={setValoresTotais}
                            setPropostaInfo={setPropostaInfo}
                        />
                    )
                }

            </div>
        </div>
    );
}
