import React from 'react';
import styles from '../FormCamposCalculoProposta.module.css';

export default function FormCamposCalculoPropostaPT({ isvIucData, isDarkTheme }) {
    return (
        <div className={`${isDarkTheme ? styles.cardDark : styles.cardLight}`}>
            <div className={styles.header}>
                <p className={`${isDarkTheme ? styles.darkTitle : styles.whiteTitle}`}>Cálculo do ISV</p>
            </div>
            {/* <hr className={styles.separator} /> */}

            <div className={styles.infoGrid}>
                {isvIucData.tipoCombustivel !== "Eletrico" && (
                    <div className={styles.infoItem}>
                        <p className={styles.label}>Cilindrada:</p>
                        <p className={`${isDarkTheme ? styles.darkValue : styles.whiteValue}`}>
                            {isvIucData.cilindrada || 'Não disponível'}
                        </p>
                    </div>
                )}

                <div className={styles.infoItem}>
                    <p className={styles.label}>Novo / Usado:</p>
                    <p className={`${isDarkTheme ? styles.darkValue : styles.whiteValue}`}>
                        {isvIucData.novoUsado === "1" ? "Novo" : "Usado"}
                    </p>
                </div>

                <div className={styles.infoItem}>
                    <p className={styles.label}>Tipo de Combustível:</p>
                    <p className={`${isDarkTheme ? styles.darkValue : styles.whiteValue}`}>
                        {isvIucData.tipoCombustivel || 'Não disponível'}
                    </p>
                </div>

                {isvIucData.tipoCombustivel !== "Eletrico" && (
                    <div className={styles.infoItem}>
                        <p className={styles.label}>Emissões Gases CO2:</p>
                        <p className={`${isDarkTheme ? styles.darkValue : styles.whiteValue}`}>
                            {isvIucData.emissoesCO2 || 'Não disponível'}
                        </p>
                    </div>
                )}

                {isvIucData.tipoCombustivel !== "Eletrico" && (
                    <div className={styles.infoItem}>
                        <p className={styles.label}>Híbrido Plug-In:</p>
                        <p className={`${isDarkTheme ? styles.darkValue : styles.whiteValue}`}>
                            {isvIucData.hibridoPlugIn ? 'Sim' : 'Não'}
                        </p>
                    </div>
                )}

                {isvIucData.tipoCombustivel !== "Eletrico" && (
                    <div className={styles.infoItem}>
                        <p className={styles.label}>Norma:</p>
                        <p className={`${isDarkTheme ? styles.darkValue : styles.whiteValue}`}>
                            {isvIucData.wltp ? 'WLTP' : 'NEDC'}
                        </p>
                    </div>
                )}

                <div className={styles.infoItem}>
                    <p className={styles.label}>Data 1º Registo:</p>
                    <p className={`${isDarkTheme ? styles.darkValue : styles.whiteValue}`}>
                        {isvIucData.dataPrimeiroRegisto || 'Não disponível'}
                    </p>
                </div>
            </div>
        </div>
    );
};
