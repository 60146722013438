import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './StatusValidationDocumentos.module.css';
import { FaClock } from 'react-icons/fa';
import { MdOutlineWatchLater } from 'react-icons/md';

export default function StatusValidationDocumentos({ }) {

    const { t } = useTranslation();

    return (
        <>
            <div className={`${styles.container}`}>
                <MdOutlineWatchLater className={styles.icon} />
                <p className={`${styles.title}`}>
                    {t('documentos.pendingValidation')}
                </p>
                <p className={`${styles.message}`}>
                    {t('documentos.documentsSent')} <span>{t('documentos.successfully')}</span>
                </p>
                <p className={`${styles.message}`}>
                    {t('documentos.willBeContacted')}
                </p>
                <p className={styles.thankYou}>
                    {t('documentos.thankYou')}
                </p>
            </div>
        </>
    );
}
