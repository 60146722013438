import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from './locales/en/translation.json';
import pt from './locales/pt/translation.json';
import es from './locales/es/translation.json';

i18n
  .use(initReactI18next)
  .init({
    resources: {
      en: { translation: en },
      pt: { translation: pt },
      es: { translation: es },
    },
    lng: 'pt', // Idioma padrão
    fallbackLng: 'pt', // Se o idioma selecionado não for encontrado
    interpolation: {
      escapeValue: false, // React já protege contra XSS
    },
  });

export default i18n;
