import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './ExpressProposta.module.css';
import { Switch } from 'antd';
import ExpressInfoDrawer from './ExpressInfoDrawer';

export default function ExpressProposta({ isDarkTheme, valoresTotais, setValoresTotais }) {
    const { t } = useTranslation();

    // State to control the drawer's visibility
    const [isDrawerVisible, setIsDrawerVisible] = useState(false);

    const handleExpress = (checked) => {
        setValoresTotais((prevData) => ({
            ...prevData,
            valorExpressAtivo: checked ? 1 : 0
        }));
    };

    // const showDrawer = () => {
    //     setIsDrawerVisible(true);
    // };

    // const closeDrawer = () => {
    //     setIsDrawerVisible(false);
    // };

    return (
        <div className={`${isDarkTheme ? styles.cardDark : styles.cardLight}`}>
            <div className={styles.header}>
                <span className={`${isDarkTheme ? styles.textDark : styles.textLight}`}>
                    Express
                    <ExpressInfoDrawer isDarkTheme={isDarkTheme} />
                </span>
                <div className={styles.labelContainer}>
                    <label className={styles.label}>
                        <Switch checked={valoresTotais.valorExpressAtivo === 1} onChange={handleExpress} />
                    </label>
                </div>
            </div>

        </div >
    );
}
