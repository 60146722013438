import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaCheck } from 'react-icons/fa6';
import { MdOutlineExpandMore, MdOutlineKeyboardArrowUp } from 'react-icons/md';
import styles from './EquipamentosViatura.module.css';

export default function EquipamentosViatura({ dataCarros, isDarkTheme }) {
    const { t } = useTranslation();
    const [moreEquipamentos, setMoreEquipamentos] = useState(false);

    // Função para alternar o estado
    const toggleEquipamentos = () => {
        setMoreEquipamentos((prevState) => !prevState);
    };

    return (
        <div className={styles.container}>
            <div className={`${isDarkTheme ? styles.cardDark : styles.cardLight}`}>
            <div
                onClick={toggleEquipamentos}
                className={`${styles.flex} ${styles['justify-between']} ${styles['cursor-pointer']}`}
            >
                <div className={styles.grid}>
                <p className={`${isDarkTheme ? styles.sectionHeaderDark : styles.sectionHeaderLight}`}>
                        {t('tables.equipamentos')}
                    </p>
                </div>

                {moreEquipamentos ? (
                    <MdOutlineKeyboardArrowUp size={25} />
                ) : (
                    <MdOutlineExpandMore size={25} />
                )}
            </div>
            {moreEquipamentos && (
                <>
                    <hr className={styles.hr} />
                    <div className={`${styles.grid} ${styles['grids']} ${styles['gap-x-10']} ${styles['gap-y-2']}`}>
                        {dataCarros.equipamento.map((item, index) => (
                            <div
                                key={index}
                                className={`${styles.flex} ${styles['justify-between']}`}
                            >
                                <span className={`${styles['text-sm']} ${styles['font-normal']}`}>
                                    {item}
                                </span>
                                <FaCheck size={15} />
                            </div>
                        ))}
                    </div>
                </>
            )}
        </div>
        </div>
    );
};
