import React from 'react';
import styles from '../FormCamposCalculoProposta.module.css';


export default function FormCamposCalculoPropostaES({ isvIucData, isDarkTheme }) {

    const formatarNumero = (valor) => {
        if (!valor || isNaN(valor)) return '0 €';
        return new Intl.NumberFormat('pt-PT', {
            style: 'currency',
            currency: 'EUR',
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
        }).format(valor);
    };

    return (
        <div className={`${isDarkTheme ? styles.cardDark : styles.cardLight}`}>
            <div className={styles.header}>
                <p className={`${isDarkTheme ? styles.darkTitle : styles.whiteTitle}`}>Calculo del IDM</p>
            </div>
            <hr className={styles.separator} />

            <div className={styles.infoGrid}>
                <div className={styles.infoItem}>
                    <p className={styles.label}>Provincia</p>
                    <p className={`${isDarkTheme ? styles.darkValue : styles.whiteValue}`}>{isvIucData.nomeProvincia || 'Sin información'}</p>
                </div>

                <div className={styles.infoItem}>
                    <p className={styles.label}>Munícipio</p>
                    <p className={`${isDarkTheme ? styles.darkValue : styles.whiteValue}`}>{isvIucData.nomeMunicipio || 'Sin información'}</p>
                </div>

                <div className={styles.infoItem}>
                    <p className={styles.label}>Marca</p>
                    <p className={`${isDarkTheme ? styles.darkValue : styles.whiteValue}`}>{isvIucData.nomeMarca || 'Sin información'}</p>
                </div>

                <div className={styles.infoItem}>
                    <p className={styles.label}>Modelo</p>
                    <p className={`${isDarkTheme ? styles.darkValue : styles.whiteValue}`}>{isvIucData.nomeModelo || 'Sin información'}</p>
                </div>

                {isvIucData.tipoCombustivel !== "Eletrico" && (
                    <div className={styles.infoItem}>
                        <p className={styles.label}>Cilindrada</p>
                        <p className={`${isDarkTheme ? styles.darkValue : styles.whiteValue}`}>{isvIucData.cilindrada || 'Sin información'}</p>
                    </div>
                )}

                <div className={styles.infoItem}>
                    <p className={styles.label}>Nuevo / Usado</p>
                    <p className={`${isDarkTheme ? styles.darkValue : styles.whiteValue}`}>{isvIucData.novoUsado === "1" ? "Nuevo" : "Usado"}</p>
                </div>

                <div className={styles.infoItem}>
                    <p className={styles.label}>Fecha de primer registro</p>
                    <p className={`${isDarkTheme ? styles.darkValue : styles.whiteValue}`}>{isvIucData.dataPrimeiroRegisto || 'Sin información'}</p>
                </div>

                <div className={styles.infoItem}>
                    <p className={styles.label}>Valor del Vehículo Nuevo</p>
                    <p className={`${isDarkTheme ? styles.darkValue : styles.whiteValue}`}>{isvIucData.valorViaturaNova ? formatarNumero(isvIucData.valorViaturaNova) : 'Sin información'}</p>
                </div>

                <div className={styles.infoItem}>
                    <p className={styles.label}>Tipo de Combustível</p>
                    <p className={`${isDarkTheme ? styles.darkValue : styles.whiteValue}`}>{isvIucData.tipoCombustivel || 'Sin información'}</p>
                </div>

                {isvIucData.tipoCombustivel !== "Eletrico" && (
                    <div className={styles.infoItem}>
                        <p className={styles.label}>Emisiones de Gases CO2</p>
                        <p className={`${isDarkTheme ? styles.darkValue : styles.whiteValue}`}>{isvIucData.emissoesCO2 || 'Sin información'}</p>
                    </div>
                )}
            </div>
        </div>
    );
};
