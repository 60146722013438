import React from 'react';
import styles from './AceitarPropostaModalPropostaAceite.module.css';
import { Button } from 'antd';
import { FaCheckCircle } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';

export default function AceitarPropostaModalPropostaAceite({ isDarkTheme, propostaInfo }) {
    const { t } = useTranslation();

    return (
        <div className={styles.container}>
            <FaCheckCircle className={styles.icon} />
            <p className={styles.title}>{t('view.proposta_aceite_title')}</p>
            <p>{t('view.proposta_aceite_contact')}</p>
            <strong>{t('view.obrigado')}</strong>
            <hr />
            <div className={styles.acceptDiv}>
                <p>
                    {t('view.proposta_aceite_documentos')}{' '}
                    <strong>{t('view.envie_documentacao')}</strong>.
                </p>
                <Button
                    key="confirm"
                    className={styles.acceptButton}
                    href={propostaInfo.linkDocumentacao}
                >
                    {t('view.enviar_documentos')}
                </Button>
            </div>
        </div>
    );
}
