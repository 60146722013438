import React from 'react';
import styles from './Error.module.css';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import logo from '../../assets/logo-branco 3.svg';
import logobranco from '../../assets/logo-branco 2.svg';

export default function Error({ parametros, isDarkTheme }) {
    const { t } = useTranslation();

    const isMobile = useMediaQuery({
        query: '(max-width: 1023px)',
    });

    return (
        <div className={styles.errorContainer}>
            <div className={`${isMobile ? styles.mobileLayout : styles.desktopLayout}`}>
                <div className={`${styles.logoSection} ${isMobile ? '' : styles.desktopSpacing}`}>
                    <a
                        href={parametros.language === 'pt' ? 'https://importrust.com' : 'https://importrust.com'}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <img
                            src={!isDarkTheme ? logo : logobranco}
                            alt="Logo Importrust"
                            className={styles.logo}
                        />
                    </a>
                    {!isMobile && (
                        <>
                            <h2 className={styles.title}>Especialistas em Importação Automóvel</h2>
                            <div className={styles.statsSection}>
                                <div className={styles.statsRow}>
                                    <div className={`${isDarkTheme ? styles.cardDarkPeq : styles.cardLightPeq}`}>
                                        <h3 className={styles.statNumber}>+2000</h3>
                                        <span className={styles.statText}>{t('login.satisfied_clients')}</span>
                                    </div>
                                    <div className={`${isDarkTheme ? styles.cardDarkPeq : styles.cardLightPeq}`}>
                                        <h3 className={styles.statNumber}>5000€</h3>
                                        <span className={styles.statText}>{t('login.average_savings')}</span>
                                    </div>
                                    <div className={`${isDarkTheme ? styles.cardDarkPeq : styles.cardLightPeq}`}>
                                        <h3 className={styles.statNumber}>10M€</h3>
                                        <span className={styles.statText}>{t('login.total_savings')}</span>
                                    </div>
                                    <div className={`${isDarkTheme ? styles.cardDarkPeq : styles.cardLightPeq}`}>
                                        <div className={styles.stars}>
                                            <span>★</span>
                                            <span>★</span>
                                            <span>★</span>
                                            <span>★</span>
                                            <span>★</span>
                                        </div>
                                        <span className={styles.statText}>{t('login.average_client_rating')}</span>
                                    </div>
                                </div>
                            </div>
                        </>
                    )}
                </div>
                <div className={styles.messageSection}>
                    <div className={`${isDarkTheme ? styles.cardDark : styles.cardLight}`}>
                        {/* <div> */}
                        <div className={styles.errorHeader}>
                            <span className={styles.errorCode}>404</span>
                            <span className={styles.errorMessage}>Oops! Página não encontrada.</span>

                            <div className={styles.errorDescription}>
                                A página que procura não está disponível. Volte à página anterior e tente novamente.
                            </div>
                            <a
                                href={parametros.language === 'pt' ? 'https://importrust.com' : 'https://importrust.com'}
                                target="_blank"
                                rel="noopener noreferrer"
                                className={styles.websiteLink}
                            >
                                Aceder ao Website
                            </a>
                        </div>
                    </div>
                </div>
                {isMobile && (
                    <>
                        <h2 className={styles.title}>Especialistas em Importação Automóvel</h2>
                        {isMobile && (
                            <div className={styles.starsFlex}>
                                <div className={styles.stars}>
                                    <span>★</span>
                                    <span>★</span>
                                    <span>★</span>
                                    <span>★</span>
                                    <span>★</span>
                                </div>
                                <span className={styles.statText}>{t('login.average_client_rating')}</span>
                            </div>
                        )}
                        <div className={styles.statsSection}>
                            <div className={styles.statsRow}>
                                <div className={`${isDarkTheme ? styles.cardDarkPeq : styles.cardLightPeq}`}>
                                    <h3 className={styles.statNumber}>+2000</h3>
                                    <span className={styles.statText}>{t('login.satisfied_clients')}</span>
                                </div>
                                <div className={`${isDarkTheme ? styles.cardDarkPeq : styles.cardLightPeq}`}>
                                    <h3 className={styles.statNumber}>5000€</h3>
                                    <span className={styles.statText}>{t('login.average_savings')}</span>
                                </div>
                                <div className={`${isDarkTheme ? styles.cardDarkPeq : styles.cardLightPeq}`}>
                                    <h3 className={styles.statNumber}>10M€</h3>
                                    <span className={styles.statText}>{t('login.total_savings')}</span>
                                </div>
                                {!isMobile && (
                                    <div className={`${isDarkTheme ? styles.cardDarkPeq : styles.cardLightPeq}`}>
                                        <div className={styles.stars}>
                                            <span>★</span>
                                            <span>★</span>
                                            <span>★</span>
                                            <span>★</span>
                                            <span>★</span>
                                        </div>
                                        <span className={styles.statText}>{t('login.average_client_rating')}</span>
                                    </div>
                                )}
                            </div>
                        </div>
                    </>
                )}
            </div>
        </div>
    );
}
