import { Button } from 'antd';
import React from 'react';
import { IoMdCloseCircle } from "react-icons/io";
import { useTranslation } from 'react-i18next';
import styles from './StatusRejectedDocumentos.module.css';
import { TbAlertCircle } from 'react-icons/tb';

export default function StatusRejectedDocumentos({ data, setData }) {
    const { t } = useTranslation();

    const handleResubmit = () => {
        setData((prevData) => ({
            ...prevData,
            status: 'pending',
        }));
    };

    const rejectedDocuments = data.estadosDocumentos?.filter(doc => doc.status === 'rejected') || [];


    return (
        <div className={styles.container}>
            <TbAlertCircle className={styles.icon} />
            <p className={styles.title}>{t('documentos.rejectedTitle')}</p>
            <p className={styles.message}>
                {t('documentos.rejectedMessage')}
            </p>
            <hr className={styles.separator} />
            <div className={styles.divs}>
                <p className={styles.message}>
                    <strong>{t('documentos.rejectedDocuments')}</strong>
                </p>
                <div>
                    {rejectedDocuments?.map((item) => (
                        <span key={item.nome} className={styles.documentName}>{item.nome}</span>
                    ))}
                </div>
            </div>
            <div className={styles.divs}>
                <p className={styles.message}>
                    <strong>{t('documentos.motivo')}:</strong>
                </p>
                <div>
                    {data.motivo}
                </div>
            </div>
            <hr className={styles.separator} />
            <p className={styles.message}>{t('documentos.pleaseResubmit')}</p>
            <p className={styles.thankYou}>{t('documentos.thankYou')}</p>
            <Button
                type="primary"
                htmlType="button"
                className={styles.submitButton}
                onClick={handleResubmit} // Função chamada ao clicar no botão
            >
                {t('documentos.resubmitButton')}
            </Button>
        </div>
    );
}
