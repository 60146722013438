import React from 'react';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import styles from './Loading.module.css';

export default function Loading({ isDarkTheme }) {
    
    return (
        <div className={styles.loadingContainer}>
            <Spin
                indicator={
                    <LoadingOutlined 
                        className={isDarkTheme ? styles.spinnerDark : styles.spinnerWhite}
                        spin 
                    />
                }
            />
        </div>
    );
}
