import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MdOutlineExpandMore, MdOutlineKeyboardArrowUp } from 'react-icons/md';
import styles from './DadosTecnicos.module.css';

export default function DadosTecnicos({ dataCarros, isDarkTheme }) {
    const { t } = useTranslation();
    const [moreDadosTecnicos, setDadosTecnicos] = useState(false);

    // Função para alternar o estado
    const toggleDadosTecnicos = () => {
        setDadosTecnicos((prevState) => !prevState);
    };

    return (
        <div className={styles.container}>
            <div className={`${isDarkTheme ? styles.cardDark : styles.cardLight}`}>
                <div
                    onClick={toggleDadosTecnicos}
                    className={`${styles.flex} ${styles['justify-between']} ${styles['cursor-pointer']}`}
                >
                    <div className={styles.grid}>
                        <p className={`${isDarkTheme ? styles.sectionHeaderDark : styles.sectionHeaderLight}`}>
                            {t('tables.dados_tecnicos')}
                        </p>
                    </div>

                    {moreDadosTecnicos ? (
                        <MdOutlineKeyboardArrowUp size={25} />
                    ) : (
                        <MdOutlineExpandMore size={25} />
                    )}
                </div>
                {moreDadosTecnicos && (
                    <>
                        <hr className={styles.hr} />
                        <div className={`${styles.grid} ${styles['grid-cols-1']} ${styles['gap-x-10']} ${styles['gap-y-2']}`}>
                            {Object.entries(dataCarros.dados_tecnicos).map(([key, value], index) => (
                                <div
                                    key={index}
                                    className={`${styles.grid} ${styles['grids']} ${styles['gap-4']} ${styles['text-sm']}`}
                                >
                                    <span className={styles['font-semibold']}>{key}:</span>
                                    <p className={styles['font-normal']}>{value}</p>
                                </div>
                            ))}
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};
