import React from 'react';
import styles from './InfosBusinessDeveloper.module.css';
import { Button } from 'antd';
import { MdOutlineEmail } from "react-icons/md";
import { TbBrandWhatsapp } from "react-icons/tb";
import { useTranslation } from 'react-i18next';

export default function InfosBusinessDeveloper({ isDarkTheme, bdInfo }) {

    const { t } = useTranslation();

    return (
        <div className={isDarkTheme ? styles.cardDark : styles.cardLight}>
            <div className={styles.infoGrid}>
                <img src={bdInfo.foto_perfil_responsavel} className={styles.profileImage} alt="Business Developer" />
                <div className={styles.textGrid}>
                    <span>{t('view.info_bd_ola')}</span>
                    <p>
                        {t('view.info_bd_frase')} <strong>{t('view.fale')}</strong>
                    </p>
                </div>
            </div>
            <div className={styles.buttonGrid}>
                <Button
                    className={styles.whatsButton}
                    href={`${bdInfo.ajuda_whatsapp}`}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <TbBrandWhatsapp /> Whatsapp
                </Button>
                <Button
                    className={styles.emailButton}
                    href={`mailto:${bdInfo.ajuda_email}`}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <MdOutlineEmail /> Email
                </Button>
            </div>
        </div>
    );
}
